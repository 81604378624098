<script setup>
const storeConfig = useStoreConfig()
const { footerLabels, whatsAppConfig } = storeToRefs(storeConfig)
const footerLabelsIsExist = computed(() => footerLabels.value?.length > 0)

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true
})

</script>

<template>
  <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
  <Head>
    <template v-for="link in head.link" :key="link.id">
      <Link :id="link.id" :rel="link.rel" :href="link.href" :hreflang="link.hreflang" />
    </template>
    <template v-for="meta in head.meta" :key="meta.id">
      <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
    </template>
  </Head>
  <Body>
  <div class="flex flex-col justify-between h-screen gap-5">
    <div class="flex-1">

      <WidgetMarquee :data-nosnippet="true"/>

      <LayoutNavigationNavbar/>

      <div class="">
        <slot/>
      </div>
    </div>

    <LayoutSectionFeatureLabels v-if="footerLabelsIsExist" />

    <LayoutSectionFooter />

    <ClientOnly>
      <AppWidgetChatViaWhatsApp v-if="whatsAppConfig" />
    </ClientOnly>
  </div>
  </Body>
  </Html>
</template>