<template>
  <TransitionRoot :show="openMobileMenu" as="template">
    <Dialog as="div" class="fixed inset-0 z-full max-w-xs block lg:hidden" @close="openMobileMenu=false">
      <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
      >
        <DialogOverlay class="fixed inset-0 bg-black bg-opacity-40 backdrop-blur-sm"/>
      </TransitionChild>

      <TransitionChild
          as="template"
          enter="transition ease-in-out duration-200 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-200 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
      >
        <DialogPanel class="relative w-full bg-white text-primary shadow-xl h-screen flex flex-col justify-between overflow-y-auto">
          <header class="px-4 pt-4 pb-2 flex justify-between">
            <button type="button" class="focus:outline-none" @click="openMobileMenu=false">
              <CancelIcon class="h-4 w-4" aria-hidden="true"/>
            </button>
          </header>
          
          <main class="flex-1 text-base">
              <Disclosure as="template" v-slot="{ open, close }" :default-open="navigationMenu.length === 1" v-for="(category, idx) in navigationMenu" :key="`ml-${category.id}`">
                <DisclosureButton class="text-left w-full flex justify-between items-start focus:outline-none p-2.5"
                    v-if="category.children.length > 0">
                  <span class="font-bold uppercase">{{ getLocaleString(category.name) }}</span>
                  <ChevronDownIcon
                      class="h-4 w-4 transform"
                      :class="open ? '-rotate-180' : 'rotate-0'"
                      aria-hidden="true"
                      v-if="navigationMenu.length !== 1"
                  />

                </DisclosureButton>
                <!--                    active-class="focus:outline-none text-secondary text-base underline cursor-pointer uppercase"-->

                <a
                    :href="getLocaleString(category.href, true)"
                    class="block focus:outline-none text-base font-bold cursor-pointer uppercase p-2.5"
                    v-else
                >
                  {{ getLocaleString(category.name) }}
                </a>

                <DisclosurePanel as="div" class="font-normal">
                  <Disclosure
                      v-for="section in category.children"
                      as="template"
                      :key="`mlc-${section.id}`"
                      v-slot="{ open, close }"
                  >
                    <DisclosureButton class="text-left w-full flex justify-between items-start text-lg">
                      <!-- is active class ? text-secondary -->
                      <div v-if="!getLocaleString(section.href)" class="flex justify-between items-center  p-3">
                        <p :id="`${getLocaleString(section.name)}-heading`" class="ml-2 font-medium text-base">
                          {{ getLocaleString(section.name) }}
                        </p>
                      </div>

                      <a :href="getLocaleString(section.href, true)" class="block font-medium text-base cursor-pointer ml-2 p-3 w-full" v-else>
                        {{ getLocaleString(section.name) }}
                      </a>

                      <div class="ml-6 flex items-center" v-if="section.children.length > 1">
                        <ChevronDownIcon
                            class="h-4 w-4 transform"
                            :class="open ? '-rotate-180' : 'rotate-0'"
                            aria-hidden="true"
                        />
                      </div>
                    </DisclosureButton>

                    <DisclosurePanel v-if="section.children.length > 1" as="div" class="p-3">
                      <ul role="list" class="ml-4 grid gap-y-2">
                        <li
                            v-for="item in section?.children"
                            :key="`scl-${item.id}`"
                            class="flex py-2"
                        >
                          <a :href="getLocaleString(item.href, true)" class="hover:text-gray-800 text-base">
                            {{ getLocaleString(item.name) }}
                          </a>
                        </li>
                      </ul>
                      <a
                          class="block p-2"
                          v-if="getLocaleString(section.href) && section.href !== 'null' && getLocaleString(section.href) !== '#'"
                          :href="getLocaleString(section.href, true)"
                      >
                        <FormButton class="w-full" color="gray" size="sm" @click="openMobileMenu=false">
                          {{ $t('listing.show-all-products') }} ({{ getLocaleString(section.name) }})
                        </FormButton>
                      </a>
                    </DisclosurePanel>
                  </Disclosure>
                  <a
                      class="block p-3"
                      v-if="getLocaleString(category.href) && getLocaleString(category.href) !== 'null' && getLocaleString(category.href) !== '#'"
                      :href="getLocaleString(category.href, true)"
                  >
                    <FormButton class="w-full" color="gray" size="sm" @click="openMobileMenu=false">
                      {{ $t('listing.show-all-products') }} ({{ getLocaleString(category.name) }})
                    </FormButton>
                  </a>
                </DisclosurePanel>
              </Disclosure>
          </main>

          <footer class="w-full bg-gray-50">
            <FormButton class="w-full text-red-500" @click="signOut" v-if="status === 'authenticated'">
              <LogoutIcon class="h-5 w-5 mr-2" aria-hidden="true"/>
              {{ $t('auth.sign-out') }}
            </FormButton>

            <div class="grid grid-cols-2 border border-y-gray-200 divide-x" v-else>
              <a href="/login" class="flex items-center justify-center p-4 hover:text-secondary" @click="openMobileMenu=false">
                {{ $t("auth.sign-in") }}
              </a>
              <a href="/register" class="flex items-center justify-center p-4 hover:text-secondary cursor-pointer" @click="openMobileMenu=false">
                {{ $t("auth.sign-up") }}
              </a>
            </div>
          </footer>
        </DialogPanel>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DisclosureButton,
  Disclosure,
  DisclosurePanel,
  DialogPanel
} from "@headlessui/vue";

import CancelIcon from "~/assets/icons/cancel.svg"
import LogoutIcon from "~/assets/icons/logout.svg"

import {
  XMarkIcon,
  ChevronDownIcon
} from "@heroicons/vue/20/solid"

const {locale} = useI18n()

const {doClose, hideOther, elements} = useAccordion()
const {status, signOut} = await useAuth()
const {user} = await useCustomer()

const openMobileMenu = useState('openMobileMenu', () => false)

const storeConfig = useStoreConfig()
const { footerMenu, navigationMenu } = storeToRefs(storeConfig)

</script>

<style scoped>

</style>